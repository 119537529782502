import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EnvService {
  // API_URL1 = 'http://142.132.181.255:3000/';
  // API_URL = 'http://142.132.181.255:3000/';

  // API_URL1 = 'http://192.168.0.103:3000/';
  // API_URL = 'http://192.168.0.103:3000/';
  // API_URL2 = 'http://192.168.0.103:3003/';

  // API_URL1 = 'http://192.168.1.40:3000/';
  // API_URL = 'http://192.168.1.40:3000/';
  // API_URL2 = 'http://192.168.1.40:3003/';

  // API_URL1 = 'http://192.168.148.216:3000/';
  // API_URL = 'http://192.168.148.216:3000/';

  // API_URL2 = 'http://192.168.148.216:3003/';

  API_URL1 = 'http://78.46.205.228:3000/';
  API_URL = 'http://78.46.205.228:3000/';

  API_URL2 = 'http://78.46.205.228:3003/';

  //  API_URL2 = 'http://172.16.18.69:3003/';

  
  // API_URL = 'http://172.16.18.69:3000/';
  // API_URL1 = 'http://172.16.18.69:3000/';

  // API_URL1 = 'http://localhost:3000/';

  // API_URL = 'http://localhost:3000/';

   // API_URL2 = 'http://10.10.30.142:3003/';


   //  API_URL1 = 'http://10.10.30.142:3000/';
   //  API_URL = 'http://10.10.30.142:3000/';

  // API_URL1 = 'http://172.16.18.7:3000/';
  // API_URL = 'http://172.16.18.7:3000/';

  // API_URL1 = 'http://172.16.19.121:3000/';
  // API_URL = 'http://172.16.19.121:3000/';

  // API_URL1 = 'http://202.21.41.138:3000/';
  // API_URL = 'http://202.21.41.138:3000/';
  constructor() { }
}




